<template>
  <div class="projectContainer">
    <div class="projectBox">
      <div class="title">
        <div class="titleLeft">按题型选择</div>
        <div class="titleRight" :class="active.type.length == Object.keys($common.getQuestionType).length ? 'activeAll':''" @click="selectAll">
          <img :src="active.type.length == Object.keys($common.getQuestionType).length ? imgList[0]:imgList[1]" alt="" srcset="">
          <span>全选</span>
        </div>
      </div>
      <div class="projectContent">
        <div
          class="projectItem"
          :class="getActive(index, $common.getQuestionType, active.type) ? 'active' : ''"
          v-for="(item, index) in $common.getQuestionType"
          :key="index"
          @click="typeClick(item,index)"
        >
          {{ item }}
        </div>
      </div>
    </div>
    <div class="projectBox">
      <div class="title numTitle">
        <div class="titleLeft">按数量选择</div>
        <div class="titleRight"></div>
      </div>
      <div class="projectContent">
        <div
          class="projectItem"
          :class="getActive(index, numList, active.number) ? 'active' : ''"
          v-for="(item, index) in numList"
          :key="index"
          @click="numClick(item, index)"
        >
          {{ item }}
        </div>
      </div>
    </div>
    <div class="startBtn" @click="startAnswer"><span>开始答题</span></div>
  </div>
</template>

<script>
import { Toast } from "vant"
export default {
  name: 'ProjectSetting',
  data () {
    return {
      numList: [30, 50, 100, 200],
      active: {
        type: [],
        number: []
      },
      imgList: [
        require('@/assets/img/helpStudy/setTypeAll.png'),
        require('@/assets/img/helpStudy/setTypeAll_no.png')
      ]
    }
  },
  filters: {
    filterActive: function (value) {
      return value.split('-').join('')
    }
  },
  methods: {
    // 题目类型点击
    typeClick (item, index) {
      console.log(item, index)
      if (this.active.type.length > 0) {
        for (const i in this.active.type) {
          if (this.active.type[i] === item) {
            this.active.type.splice(i, 1)
            break
          } else if (i == this.active.type.length - 1) {
            this.active.type.push(item)
            console.log(item, 'item2')
            break
          }
        }
      } else {
        this.active.type.push(item)
      }
      console.log(this.active.type)
    },
    // 数量点击
    numClick (item, index) {
      this.active.number.splice(0, 1)
      this.active.number.push(item)
    },
    // 全选
    selectAll () {
      if (this.active.type.length == Object.keys(this.$common.getQuestionType).length) {
        this.active.type = []
      } else {
        this.active.type = Object.values(this.$common.getQuestionType)
      }
    },
    // 获取激活状态
    getActive (index, list, activeList) {
      if (activeList.length > 0) {
        for (let i in activeList) {
          if (activeList[i] == list[index]) {
            return true
          }
        }
      } else {
        return false
      }
    },
    // 开始答题
    startAnswer () {
      if(this.active.number.length == 0 || this.active.type.length == 0) {
        Toast({
          message: '请选择题型或题目数量'
        })
        return;
      }
      this.$emit('startAnswer', this.active)
    }
  }
}
</script>

<style lang="scss" scoped>
.projectContainer {
  background: #f9f9f9;
  padding: 27px 24px 0 24px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #363840;
  line-height: 14px;
  height: inherit;
  .projectBox {
    .numTitle {
      margin-top: 40px;
    }
    .title {
      display: flex;
      justify-content: space-between;
      height: 16px;
      margin-bottom: 20px;
      .titleLeft {
      }

      .activeAll{
        color: #1a7ee2 !important;
      }
      .titleRight {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80px;
        img {
          height: 12px;
          width: auto;
          padding-right: 5px;
        }
        span {
          height: 16px;
        }
      }
    }

    .projectContent {
      display: grid;
      flex-wrap: wrap;
      grid-template-columns: 30% 30% 30%;
      grid-row-gap: 20px;
      justify-content: space-between;
      justify-items: center;
      align-items: center;
      .active {
        color: #1a7ee2 !important;
        background: rgba(26, 126, 226, 0.1)  !important;
        border-color: #1a7ee2  !important;
      }
      .projectItem {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 32px;
        background: #efefef;
        border-radius: 22px;
        border: 1px solid #efefef;
      }
    }
  }

  .startBtn {
    height: 44px;
    background: #1a7ee2;
    border-radius: 30px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 100px 16px 0;
    &:active {
      box-shadow: 0px 5px 20px grey;
    }
    span {
    }
  }
}
</style>
