<!-- 专项练习/真题精编/模拟考试/ -->
<template>
  <projectSetting
    v-if="showSetting"
    @startAnswer='startAnswer'
  />
  <specialPaper
    :activeData="activeData"
    v-else
  />
</template>

<script>
import projectSetting from './projectSetting'
import specialPaper from './specialPaper'
export default {
  name: 'SpecialProject',
  components: {
    projectSetting,
    specialPaper
  },
  data () {
    return {
      showSetting: true,
      activeData: {},
    }
  },
  methods: {
    startAnswer (active) {
      this.showSetting = !this.showSetting
      this.activeData = active
      console.log(active, this.showSetting,985211)
    }
  },
}
</script>

<style>

</style>